let localLanguageOverride: string | null = null;
export function overrideLocalLanguage(language: string | null) {
  localLanguageOverride = language;
}

export function getLocalLanguage(): string {
  return (localLanguageOverride ?? typeof navigator !== "undefined")
    ? navigator?.language
    : "en-US";
}
