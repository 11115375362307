import { paths } from "@classdojo/ts-api-types";
import {
  useQuery,
  QueryFilters,
  SetDataOptions,
  InvalidateOptions,
  InvalidateQueryFilters,
  UseQueryOptions,
  UseQueryResult,
  QueryKey,
  Query,
  CancelOptions,
} from "@tanstack/react-query";
import callApi from "@web-monorepo/infra/callApi";
import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { produce } from "immer";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import { APIResponse, EndpointQueryParameters, EndpointParams } from "../api/apiTypesHelper";
import { makeApiErrorMessage } from "./error";
import { queryClient } from "./queryClient";
import { registerQuery, makeQueryKey } from "./queryKey";
import { mergeFns, useQueryScope } from "./queryUtils";
import { buildUrl } from "./urlBuilder";
import { buildHeaders } from "./headersBuilder";
import { urlPatternFromFetcher } from "./urlPatternFromFetcher";
import { NOOP, WAITING_FOR_DEPENDENCIES, CUSTOM_HEADERS } from ".";

export type MemberQueryParams<
  Path extends keyof paths,
  QueryParams extends keyof EndpointQueryParameters<Path>,
> = EndpointParams<Path, QueryParams> & {
  [CUSTOM_HEADERS]?: Record<string, string>;
};

type FullMemberQueryParams<Path extends keyof paths, QueryParams extends keyof EndpointQueryParameters<Path>> =
  | MemberQueryParams<Path, QueryParams>
  | typeof NOOP
  | typeof WAITING_FOR_DEPENDENCIES;

// React Query options exposed at the configuration level. Some options are
// facilitated by makeMemberQuery and some would cause unexpected behavior with
// the internal logic.
type ExposedUseQueryOptions<TQueryFnData> = Omit<
  UseQueryOptions<TQueryFnData, Error>,
  | "queryKey"
  | "queryFn"
  | "enabled"
  | "meta"
  | "queryKeyHashFn"
  | "select"
  | "useErrorBoundary"
  | "onSuccess"
  | "onError"
  | "onSettled"
>;

type EnhancedUseQueryOptions<MemberType, Params> = ExposedUseQueryOptions<MemberType> & {
  onSuccess?: (data: MemberType, params: Params) => void;
  onError?: (error: Error, params: Params) => void;
  onSettled?: (data: MemberType | undefined, error: Error | null, params: Params) => void;
};

export type MemberQueryData<Path extends keyof paths> = "get" extends keyof paths[Path]
  ? APIResponse<Path, "get">
  : never;

// # Regarding variedHeaders:
// Sometimes an HTTP request header can influence the contents of the HTTP response.
// In those cases, the `Vary` response header should include a list of request headers
// that influenced the response content. Ideally, we'd use that to determine which
// request headers need to be a part of the React Query cacheKey, but React Query wants
// you to provide the cacheKey upfront before the request is ever made. Instead, we're
// maintaining a list here as this is an edge case currently.

export type MemberQueryConfig<
  Path extends keyof paths,
  QueryParams extends keyof EndpointQueryParameters<Path>,
  MemberType = MemberQueryData<Path>,
> = {
  fetcherName: string;
  path: Path;
  query?: Omit<EndpointQueryParameters<Path>, QueryParams>;
  queryParams?: QueryParams[];
  dontThrowOnStatusCodes?: number[];
  defaultHeaders?: Record<string, string>;
  variedHeaders?: string[];
  shouldFetchImmediately?: boolean;
} & EnhancedUseQueryOptions<MemberType, MemberQueryParams<Path, QueryParams>>;

export type MemberQueryType<
  Path extends keyof paths,
  QueryParams extends keyof EndpointQueryParameters<Path>,
  MemberType = MemberQueryData<Path>,
> = {
  (
    params: FullMemberQueryParams<Path, QueryParams>,
    options?: EnhancedUseQueryOptions<MemberType, MemberQueryParams<Path, QueryParams>>,
  ): UseQueryResult<MemberType, Error>;

  invalidateQueries(
    params?: Partial<MemberQueryParams<Path, QueryParams>>,
    filters?: Omit<InvalidateQueryFilters, "queryKey">,
    options?: InvalidateOptions,
  ): Promise<void>;

  shouldInvalidateQueries(
    shouldInvalidateQuery: (
      data: MemberType | undefined,
      params: MemberQueryParams<Path, QueryParams>,
    ) => boolean | null | undefined,
  ): void;

  cancelQueries(
    params?: Partial<MemberQueryParams<Path, QueryParams>>,
    filters?: Omit<QueryFilters, "queryKey">,
    options?: CancelOptions,
  ): Promise<void>;

  getQueryData(params?: MemberQueryParams<Path, QueryParams>): MemberType | undefined;

  getQueriesData(
    params?: Partial<MemberQueryParams<Path, QueryParams>>,
    filters?: Omit<InvalidateQueryFilters, "queryKey">,
  ): [QueryKey, MemberType | undefined][];

  setQueriesData(
    updater: ((draft: MemberType) => MemberType | void) | MemberType | null,
    params?: Partial<MemberQueryParams<Path, QueryParams>>,
    filters?: Omit<QueryFilters, "queryKey">,
    options?: SetDataOptions,
  ): void;
};

export function makeMemberQuery<
  Path extends keyof paths,
  QueryParams extends keyof EndpointQueryParameters<Path> = never,
  MemberType = MemberQueryData<Path>,
>(config: MemberQueryConfig<Path, QueryParams, MemberType>): MemberQueryType<Path, QueryParams, MemberType> {
  const {
    path,
    query,
    queryParams,
    dontThrowOnStatusCodes = [],
    defaultHeaders,
    variedHeaders,
    fetcherName,
    onSuccess: onSuccessConfig,
    onError: onErrorConfig,
    onSettled: onSettledConfig,
    shouldFetchImmediately = true,
    ...extraConfig
  } = config;

  const urlPattern = urlPatternFromFetcher(path, query, queryParams);

  registerQuery(fetcherName);

  function useQueryWrapper(
    params: FullMemberQueryParams<Path, QueryParams> = {} as MemberQueryParams<Path, QueryParams>,
    options: EnhancedUseQueryOptions<MemberType, MemberQueryParams<Path, QueryParams>> = {},
  ) {
    const { onSuccess: onSuccessOption, onError: onErrorOption, onSettled: onSettledOption, ...extraOptions } = options;
    const queryKey = useMemo(() => makeQueryKey({ fetcherName, params, variedHeaders }), [params]);

    // parsedUrl = f(urlPattern, params), e.g. "api/123/classes"
    // can be undefined if fetcher is a noop or if we're waiting on dependencies
    const { parsedUrl, headers } = useMemo(() => {
      if (typeof params === "object") {
        const parsedUrl = buildUrl({ params, urlPattern }, true);
        const headers = buildHeaders(defaultHeaders, params[CUSTOM_HEADERS]);
        return { parsedUrl, headers };
      }

      return { parsedUrl: undefined, headers: undefined };
    }, [params]);

    const awaitingDependencies = params === WAITING_FOR_DEPENDENCIES;
    const isNoop = params === NOOP;
    const enabled = shouldFetchImmediately && !isNoop && !awaitingDependencies && Boolean(parsedUrl);

    const { queryFn, onSuccess, onError, onSettled } = useQueryScope<MemberType>({
      queryFn: async () => {
        try {
          const { body: data } = await callApi({
            method: "GET",
            path: parsedUrl!,
            headers,
          });
          return data;
        } catch (ex: unknown) {
          if (!(ex instanceof Error)) {
            throw ex;
          }

          if (ex instanceof APIResponseError) {
            ex.isExpected = dontThrowOnStatusCodes.includes(ex.response.statusCode);

            const headingMessage = ex.isExpected
              ? "API error in memberFetcher, caught and treated as expected"
              : "Unexpected API error in memberFetcher";

            ex.message = makeApiErrorMessage({
              headingMessage,
              response: ex.response,
              name: fetcherName,
              parsedUrl: parsedUrl!,
              type: "Member",
            });
          }

          throw ex;
        }
      },
      onSuccess: (data) => {
        onSuccessConfig?.(data, params as MemberQueryParams<Path, QueryParams>);
      },
      onError: (error) => {
        onErrorConfig?.(error, params as MemberQueryParams<Path, QueryParams>);
      },
      onSettled: (data, error) => {
        return onSettledConfig?.(data, error, params as MemberQueryParams<Path, QueryParams>);
      },
    });

    const result = useQuery<MemberType, Error>({
      queryFn,
      onSuccess: mergeFns(onSuccess, (data) => onSuccessOption?.(data, params as MemberQueryParams<Path, QueryParams>)),
      onError: mergeFns(onError, (error) => onErrorOption?.(error, params as MemberQueryParams<Path, QueryParams>)),
      onSettled: mergeFns(onSettled, (data, error) =>
        onSettledOption?.(data, error, params as MemberQueryParams<Path, QueryParams>),
      ),
      queryKey,
      enabled,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retryOnMount: false,
      staleTime: Infinity,
      retry: false,
      ...extraConfig,
      ...extraOptions,
    });

    if (!(result.error instanceof APIResponseError && result.error?.isExpected) && result.error) {
      throw result.error;
    }

    return result;
  }

  useQueryWrapper.shouldInvalidateQueries = (
    shouldInvalidateQuery: (
      data: MemberType | undefined,
      params: MemberQueryParams<Path, QueryParams>,
    ) => boolean | null | undefined,
  ): void => {
    const queriesData = queryClient.getQueriesData<MemberType>({
      queryKey: makeQueryKey({ fetcherName }),
    });
    queriesData.forEach(([queryKey, data]) => {
      const [, params] = queryKey as [string, MemberQueryParams<Path, QueryParams>];
      if (shouldInvalidateQuery(data, params)) {
        useQueryWrapper.invalidateQueries(params);
      }
    });
  };

  useQueryWrapper.invalidateQueries = async (
    params?: Partial<MemberQueryParams<Path, QueryParams>>,
    filters?: Omit<InvalidateQueryFilters, "queryKey">,
    options?: InvalidateOptions,
  ): Promise<void> => {
    return queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName, params }), ...filters }, options);
  };

  useQueryWrapper.cancelQueries = async (
    params?: Partial<MemberQueryParams<Path, QueryParams>>,
    filters?: Omit<QueryFilters, "queryKey">,
    options?: CancelOptions,
  ): Promise<void> => {
    return queryClient.cancelQueries({ queryKey: makeQueryKey({ fetcherName, params }), ...filters }, options);
  };

  useQueryWrapper.getQueryData = (params?: MemberQueryParams<Path, QueryParams>): MemberType | undefined => {
    const queryKey = makeQueryKey({ fetcherName, params });
    return queryClient.getQueryData<MemberType>(queryKey);
  };

  useQueryWrapper.getQueriesData = (
    params?: Partial<MemberQueryParams<Path, QueryParams>>,
    filters?: Omit<InvalidateQueryFilters, "queryKey">,
  ): [QueryKey, MemberType | undefined][] => {
    return queryClient.getQueriesData<MemberType>({
      queryKey: makeQueryKey({ fetcherName, params }),
      ...filters,
    });
  };

  useQueryWrapper.setQueriesData = (
    updater: ((draft: MemberType) => MemberType | void) | MemberType | null,
    params?: Partial<MemberQueryParams<Path, QueryParams>>,
    filters?: Omit<QueryFilters, "queryKey">,
    options?: SetDataOptions,
  ): void => {
    const searchByQueryKey = makeQueryKey({ fetcherName, params });
    const queriesToUpdate: any[] = queryClient.getQueryCache().findAll({ queryKey: searchByQueryKey, ...filters });

    const wrappedUpdater = (data: MemberType): MemberType | null => {
      if (updater instanceof Function) {
        return produce(data, updater) as MemberType;
      } else {
        return updater;
      }
    };

    queriesToUpdate.forEach(async (query: Query<MemberType>) => {
      const { state, queryKey } = query;
      const { data, fetchStatus } = state;
      if (!data) return;

      const reduced = wrappedUpdater(data);
      if (isEqual(data, reduced)) return;

      const isFetching = fetchStatus === "fetching";
      if (isFetching) {
        await queryClient.cancelQueries({ queryKey, exact: true });
      }

      queryClient.setQueriesData({ queryKey, exact: true }, reduced, options);

      if (isFetching) {
        await queryClient.refetchQueries({ queryKey, exact: true });
      }
    });
  };

  return useQueryWrapper;
}
