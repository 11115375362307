import noop from "lodash/noop";
import omit from "lodash/omit";
import { useState, useCallback, useEffect } from "react";

import { localStorage } from "@web-monorepo/safe-browser-storage";
import env from "../utils/env";
declare global {
  interface Window {
    Cypress?: {
      _onlyForCypressPushStoryPostReads: boolean;
      navigateTo?: (url: string | URL) => void;
      sounds?: { play: (soundName: string) => any };
    };
  }
}

//
// dev toolbar visibility
//
const setIsDevToolsEnabled = (value: string) => {
  localStorage.setItem("devTools.enable", value);
};

const getIsDevToolsEnabled = () => {
  return localStorage.getItem("devTools.enable") === "true" || false;
};

export const useIsDevToolsEnabled = () => {
  if (typeof window === "undefined") return { isDevToolsEnabled: false };
  // tracks if dev tools are available to user or not
  const isRunningOnCypress = window.Cypress;
  const hasAccessToDevTools = !isRunningOnCypress;

  let devToolsQSValue: string | null = env.isDev ? "true" : null;
  if (window.location?.href?.includes("dev-tools=true")) devToolsQSValue = "true";
  if (window.location?.href?.includes("dev-tools=false")) devToolsQSValue = "false";

  if (hasAccessToDevTools && devToolsQSValue) {
    setIsDevToolsEnabled(devToolsQSValue);
  }

  // tracks if dev tools should be displayed or not
  return { isDevToolsEnabled: hasAccessToDevTools && getIsDevToolsEnabled() };
};

//
// dev toolbar window mode
//
const setIsNewWindow = (value: boolean) => {
  localStorage.setItem("devTools.isNewWindow", String(value));
};

const getIsNewWindow = () => {
  return localStorage.getItem("devTools.isNewWindow") === "true" || false;
};

const useIsNewWindow = () => {
  const [isNewWindow, setIsNewWindowState] = useState(getIsNewWindow());

  const _setIsNewWindow = useCallback((value: boolean) => {
    setIsNewWindowState(value);
    setIsNewWindow(value);
  }, []);

  return { isNewWindow, setIsNewWindow: _setIsNewWindow };
};

//
// dev toolbar panel dock position
//
export const PANEL_DISPLAY_SIDES = {
  Left: "left",
  Right: "right",
} as const;

const setPanelDisplaySide = (value: "left" | "right") => {
  localStorage.setItem("devTools.panelSide", value);
};

const getPanelDisplaySide = () => {
  return localStorage.getItem("devTools.panelSide") || PANEL_DISPLAY_SIDES.Right;
};

const usePanelDisplaySide = () => {
  const [panelDisplaySide, setPanelDisplaySideState] = useState(getPanelDisplaySide());

  // `value` param should be one of `left` or `right`
  const _setPanelDisplaySide = useCallback((value: "left" | "right") => {
    setPanelDisplaySideState(value);
    setPanelDisplaySide(value);
  }, []);

  return { panelDisplaySide, setPanelDisplaySide: _setPanelDisplaySide };
};

//
// dev toolbar feature switch overrides
//

// list of event handlers registered to get called when a new event is logged.
// we need this so the feature switches section UI updates when we change a feature switch value
let onFeatureSwitchOverridesChangeHandlers: ((fsOverrides: Record<string, unknown>) => void)[] = [];

function useOnFeatureSwitchOverridesChangeHandler(
  setFeatureSwitchOverridesState: (state: Record<string, unknown>) => void,
) {
  useEffect(() => {
    onFeatureSwitchOverridesChangeHandlers = [
      ...onFeatureSwitchOverridesChangeHandlers,
      setFeatureSwitchOverridesState,
    ];

    return () => {
      onFeatureSwitchOverridesChangeHandlers = onFeatureSwitchOverridesChangeHandlers.filter(
        (h) => h !== setFeatureSwitchOverridesState,
      );
    };
  }, [setFeatureSwitchOverridesState]);
}

function notifyOnFeatureSwitchOverridesChange(fsOverrides: Record<string, unknown>) {
  onFeatureSwitchOverridesChangeHandlers.forEach((handler) => handler(fsOverrides));
}

const setFeatureSwitchOverrides = (fsOverrides: Record<string, unknown>) => {
  localStorage.setItem("devTools.featureSwitchOverrides", JSON.stringify(fsOverrides));
  notifyOnFeatureSwitchOverridesChange(fsOverrides);
};

const getFeatureSwitchOverrides = (): any => {
  try {
    const featureSwitchOverrides = localStorage.getItem("devTools.featureSwitchOverrides");
    return featureSwitchOverrides ? JSON.parse(featureSwitchOverrides) : {};
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch {
    return {};
  }
};

// Loads feature switch override values from localStorage and re-runs all existing
// hook instances when new overrides are saved.
// This is needed because featureSwitches pod uses one instance of this hook
// to load the override values, and the dev toolbar feature switches section uses
// another instance to set new values for the feature switches, so we need to trigger
// re-runs of all instances so that the dev toolbar feature switches section displays
// the updated values, otherwise, the user would select a value for the feature switch and
// the dev toolbar UI would not update.
const useFeatureSwitchOverrides = (): any => {
  const [featureSwitchOverrides, setFeatureSwitchOverridesState] = useState(getFeatureSwitchOverrides());

  useOnFeatureSwitchOverridesChangeHandler(setFeatureSwitchOverridesState);

  const _setFeatureSwitchOverride = useCallback(
    (switchName: string, value: string) => {
      const updatedFeatureSwitchOverrides = {
        ...featureSwitchOverrides,
        [switchName]: value,
      };
      setFeatureSwitchOverridesState(updatedFeatureSwitchOverrides);
      setFeatureSwitchOverrides(updatedFeatureSwitchOverrides);
    },
    [featureSwitchOverrides],
  );

  const clearFeatureSwitchOverride = useCallback(
    (switchName: string) => {
      const updatedFeatureSwitchOverrides = omit(featureSwitchOverrides, [switchName]);
      setFeatureSwitchOverridesState(updatedFeatureSwitchOverrides);
      setFeatureSwitchOverrides(updatedFeatureSwitchOverrides);
    },
    [featureSwitchOverrides],
  );

  const clearAllFeatureSwitchOverrides = useCallback(() => {
    const updatedFeatureSwitchOverrides = {};
    setFeatureSwitchOverridesState(updatedFeatureSwitchOverrides);
    setFeatureSwitchOverrides(updatedFeatureSwitchOverrides);
  }, []);

  const hasFeatureSwitchOverrides = Object.keys(featureSwitchOverrides).length > 0;

  // we don't want to have feature switch overrides if user has disabled the dev tools
  // or doesn't have access to them, no matter what is stored in localStorage
  const { isDevToolsEnabled } = useIsDevToolsEnabled();
  if (!isDevToolsEnabled) {
    return {
      featureSwitchOverrides: {},
      hasFeatureSwitchOverrides: false,
      setFeatureSwitchOverride: noop,
      clearFeatureSwitchOverride: noop,
      clearAllFeatureSwitchOverrides: noop,
    };
  }

  return {
    featureSwitchOverrides,
    hasFeatureSwitchOverrides,
    setFeatureSwitchOverride: _setFeatureSwitchOverride,
    clearFeatureSwitchOverride,
    clearAllFeatureSwitchOverrides,
  };
};

export const DevToolsSettings = {
  useIsDevToolsEnabled,
  useIsNewWindow,
  usePanelDisplaySide,
  useFeatureSwitchOverrides,
};
