import { parse, format, isValid } from "date-fns";
import { getLocalLanguage } from "./language";

// localizations extracted from date-fns/locale:
const LOCAL_TIME_FORMATS: Record<string, string> = {
  af: "HH:mm",
  ar: "HH:mm",
  arDZ: "h:mm a",
  arEG: "h:mm a",
  arMA: "h:mm a",
  arSA: "h:mm a",
  arTN: "HH:mm",
  az: "H:mm",
  be: "H:mm",
  beTarask: "H:mm",
  bg: "HH:mm",
  bn: "h:mm a",
  bs: "HH:mm",
  ca: "HH:mm",
  cs: "H:mm",
  cy: "h:mm a",
  da: "HH:mm",
  de: "HH:mm",
  deAT: "HH:mm",
  el: "h:mm a",
  en: "h:mm a", // sorry, it's america.
  enAU: "h:mm a",
  enCA: "h:mm a",
  enGB: "HH:mm",
  enIE: "HH:mm",
  enIN: "h:mm a",
  enNZ: "h:mm a",
  enUS: "h:mm a",
  enZA: "HH:mm",
  eo: "H m",
  es: "HH:mm",
  et: "HH:mm",
  eu: "HH:mm",
  faIR: "h:mm a",
  fi: "HHmm",
  fr: "HH:mm",
  frCA: "HH:mm",
  frCH: "HH:mm",
  fy: "HH:mm",
  gd: "h:mm a",
  gl: "HH:mm",
  gu: "hh:mm a",
  he: "H:mm",
  hi: "h:mm a",
  hr: "HH:mm",
  ht: "HH:mm",
  hu: "H:mm",
  hy: "HH:mm",
  id: "HHmm",
  is: "HH:mm",
  it: "HH:mm",
  itCH: "HH:mm",
  ja: "Hmm",
  jaHira: "Hmm",
  ka: "h:mm a",
  kk: "H:mm",
  km: "h:mm a",
  kn: "hh:mm a",
  ko: "a H mm",
  lb: "HH:mm",
  lt: "HH:mm",
  lv: "HH:mm",
  mk: "HH:mm",
  mn: "H:mm",
  ms: "HHmm",
  mt: "HH:mm",
  nb: "HH:mm",
  nl: "HH:mm",
  nlBE: "HH:mm",
  nn: "HH:mm",
  oc: "HH:mm",
  pl: "HH:mm",
  pt: "HH:mm",
  ptBR: "HH:mm",
  ro: "HH:mm",
  ru: "H:mm",
  sk: "H:mm",
  sl: "HH:mm",
  sq: "h:mm a",
  sr: "HH:mm",
  srLatn: "HH:mm",
  sv: "HH:mm",
  ta: "a h:mm",
  te: "h:mm a",
  th: "H:mm",
  tr: "HH:mm",
  ug: "h:mm a",
  uk: "H:mm",
  uz: "h:mm",
  uzCyrl: "H:mm",
  vi: "HH:mm",
  zh: "a h:mm",
  zhCN: "a h:mm",
  zhHK: "a h:mm",
  zhTW: "a h:mm",
};

export function formatLocalTime(date: Date) {
  const locale = getLocalLanguage()?.split("-") || [];
  const firstLocale = locale[0];
  const localizedFormat =
    LOCAL_TIME_FORMATS[locale.join("")] ||
    (firstLocale && LOCAL_TIME_FORMATS[firstLocale]) ||
    // h:mm a was used as the global default previously, so to make the tests work nicely, we'll continue defaulting to that
    "h:mm a";

  return format(date, localizedFormat);
}

export function parseLocalTime(time: string, referenceDate?: Date) {
  const locale = getLocalLanguage()?.split("-") || [];
  const firstLocale = locale[0];
  const localizedFormat =
    LOCAL_TIME_FORMATS[locale.join("")] ||
    (firstLocale && LOCAL_TIME_FORMATS[firstLocale]) ||
    // h:mm a was used as the global default previously, so to make the tests work nicely, we'll continue defaulting to that
    "h:mm a";

  const laxFormatOptions = [
    localizedFormat,
    localizedFormat.replace("a", "").trim(), // try without am/pm
    localizedFormat.replace(/m+/i, "").trim(), // try without minutes, but including a separator
    localizedFormat.replace(/[^a-z]?m+/i, "").trim(), // try without minutes and separator
    localizedFormat.replace(/h+/i, "").trim(), // try without hours, keeping separators
    localizedFormat.replace(/m+/i, "").replace("a", "").trim(), // try without minutes or am/pm
    localizedFormat
      .replace(/[^a-z]?m+/i, "")
      .replace("a", "")
      .trim(), // try without minutes, separator or am/pm
    "HH:mm", // just try out standard miliatory time as well
  ];

  for (const format of laxFormatOptions) {
    const parsed = parse(time, format, referenceDate || new Date());

    if (isValid(parsed)) {
      return parsed;
    }
  }

  return new Date(NaN);
}
